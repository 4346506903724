import React, { useContext, useState, useEffect } from "react";
import { Link } from "gatsby";
import { CurrentUserContext } from "../providers/auth";
import { Router } from "@reach/router";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Loading from "../components/loading";
import SignInAgain from "../components/sign-in-again";

const ChangePasswordPage = () => {
    const [success, setSuccess] = useState(false);
    const currentUser = useContext(CurrentUserContext);
    const [view, setView] = useState(<Loading />);
    useEffect(() => {
        if (currentUser) {
            currentUser
                .getIdToken()
                .then(() => {
                    setView(
                        <div className="flex flex-col md:justify-center items-center p-4 w-full h-screen -mt-16 pt-16">
                            <div className="absolute inset-0 mesh-bg -z-10" />
                            {success ? (
                                <LoginAgain />
                            ) : (
                                <PasswordBox
                                    onSucccess={() => {
                                        setSuccess(true);
                                    }}
                                />
                            )}
                        </div>
                    );
                })
                .catch(() => setView(<SignInAgain />));
        } else {
            setView(<SignInAgain />);
        }
    }, [currentUser, success]);

    return (
        <>
            <Seo title="Change Password" />
            {view}
        </>
    );
};

export default function ChangePassword() {
    return (
        <Layout>
            <Seo title="Change Password" />
            <Router>
                <ChangePasswordPage path="change-password" />
            </Router>
        </Layout>
    );
}

// local components

const LoginAgain = () => {
    const currentUser = useContext(CurrentUserContext);
    currentUser.signOut();
    return (
        <div className="flex flex-col rounded w-full md:w-1/2 lg:w-1/3 bg-lightgreen1 px-4 py-6">
            <div className="flex flex-row items-center">
                <span>
                    Your new password is set. Go to
                    <Link to="/signin" className="mx-1 underline">
                        Sign in
                    </Link>
                </span>
            </div>
        </div>
    );
};

const PasswordBox = ({ onSucccess }) => {
    const currentUser = useContext(CurrentUserContext);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [inputOldPassword, setInputOldPassword] = useState(false);
    const [inputPassword, setInputPassword] = useState(false);
    const [inputConfirmPassword, setInputConfirmPassword] = useState(false);
    const [error, setError] = useState(null);
    return (
        <div className="flex flex-col rounded w-full md:w-1/2 lg:w-1/3 bg-lightgreen1 px-4 py-6">
            <h1 className="text-lg mb-2">Change Password</h1>
            <input
                className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                type="password"
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => {
                    setOldPassword(e.target.value);
                    setInputOldPassword(true);
                    setError(null);
                }}
            />
            {inputOldPassword && !oldPassword ? (
                <div className="flex flex-row justify-center text-red text-xs">
                    Old password is required
                </div>
            ) : null}
            <input
                className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => {
                    setNewPassword(e.target.value);
                    setInputPassword(true);
                    setError(null);
                }}
            />
            <div className="flex flex-row text-gray-400 text-xs px-4">
                Password shall be minimum 8 characters including uppercase
                letter, lowercase letter and number
            </div>
            {inputPassword && !newPassword ? (
                <div className="flex flex-row justify-center text-red text-xs">
                    Password is required
                </div>
            ) : null}
            <input
                className="rounded-full px-4 py-2 text-xs mt-4 mb-2"
                type="password"
                placeholder="Confirm New Password"
                value={confirmNewPassword}
                onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                    setInputConfirmPassword(true);
                    setError(null);
                }}
            />
            {inputConfirmPassword && newPassword !== confirmNewPassword ? (
                <div className="flex flex-row justify-center text-red text-xs">
                    Password and Confirm Password are not matched
                </div>
            ) : null}
            <button
                className="primary btn my-4"
                onClick={() => {
                    // if (newPassword && newPassword === confirmNewPassword) {
                    currentUser
                        .changePassword(oldPassword, newPassword)
                        .then(() => {
                            setError(null);
                            onSucccess();
                        })
                        .catch((err) => {
                            setError(err);
                        });
                    // }
                }}
                disabled={
                    !newPassword ||
                    !oldPassword ||
                    !confirmNewPassword ||
                    newPassword !== confirmNewPassword
                }
            >
                Change password
            </button>
            {error ? (
                <div className="flex flex-row justify-center items-center h-1 py-2 text-red text-sm">
                    {error.code === "InvalidParameterException"
                        ? "Input parameter invalid"
                        : error.message}
                </div>
            ) : null}
        </div>
    );
};
